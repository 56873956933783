<template>
  <div class="series">
    <div class="banner">
      <div class="textBox">
        <div class="title">Agility 9520 Series</div>
        <div class="smallTitle">2D Wired Barcode Scanner</div>
        <div class="iconTextBox">
          <div class="left">
            <img src="../../../assets/9520网站配图/图标/路径 5.png" alt="" />
            Barcode
          </div>
          <div class="right">
            <img src="../../../assets/9520网站配图/图标/路径 4.png" alt="" />
            QR/PDF41/DPM
          </div>
        </div>
      </div>
    </div>
    <div class="video">
      <video
        src="../../../assets/9520网站配图/Agility_9520_Series.mp4"
        muted
        autoplay
        loop
        webkit-playsinline
        playsinline
      ></video>
    </div>
    <div class="high-definition">
      <div class="box">
        <div class="title">
          <img src="../../../assets/9520网站配图/图标/路径 1.png" alt="" />
          High-definition camera
        </div>
        <div class="text">
          Equipped with advanced decoding algorithm and dual-color lighting, it
          rapidly identifies in low-light conditions.
        </div>
      </div>
    </div>
    <div class="preciseRecognition">
      <div class="box">
        <div class="title">
          <img src="../../../assets/9520网站配图/图标/分组 4.png" alt="" />
          Precise recognition
        </div>
        <div class="text">
          It can rapidly and precisely identify damaged, stained, wrinkled, and
          colored barcodes, suitable for metal and DPM codes in light
          manufacturing.
        </div>
      </div>
    </div>
    <div class="product">
      <div class="line"></div>
      <div class="scanWindow">
        <div class="text">Scanning window</div>
      </div>
      <div class="Button">
        <div class="text">Button</div>
      </div>
      <div class="DataCableInterface">
        <div class="text">Data Cable Interface</div>
      </div>
      <div class="Buzzer">
        <div class="text">Buzzer</div>
      </div>
      <div class="Indicator">
        <div class="text">Indicator</div>
      </div>
    </div>
    <div class="Wide-angle">
      <div class="box">
        <div class="title">
          <img src="../../../assets/9520网站配图/图标/路径 20.png" alt="" />
          <div>
            <div>Wide-angle camera</div>
          </div>
        </div>
        <div class="text">
          The reading depth of a standard 13mil EAN13 code is 6-46cm, with a
          horizontal field of view angle of 50° and a vertical field of view
          angle of 38°.
        </div>
      </div>
    </div>
    <div class="Global">
      <div class="box">
        <div class="title">
          <img src="../../../assets/9520网站配图/图标/路径 3.png" alt="" />
          Global exposure technology
        </div>
        <div class="text">
          With a motion tolerance of 2m/s and a frame rate of 120FPS, moving
          barcodes are easily captured.
        </div>
      </div>
    </div>
    <div class="Trigger">
      <div class="box">
        <div class="title">
          <img src="../../../assets/9520网站配图/图标/路径 2.png" alt="" />
          Trigger 5 Million times
        </div>
        <div class="text">
          The robust high-life rebound trigger can endure 5 million presses,
          with reinforced button protection to safeguard the internal trigger
          switch from damage.
        </div>
        <div class="smallText">*Through the Compax laboratory trigger test</div>
      </div>
    </div>
    <div class="IP52Protection">
      <div class="box">
        <div class="title">
          <img src="../../../assets/9520网站配图/图标/6.png" alt="" />
          IP52 Protection
        </div>
        <div class="text">
          The dusty environment does not affect its use, and it can withstand
          multiple impacts from falling onto a cement floor from a height of 1.5
          meters.
        </div>
        <div class="smallText">*Through the Compax laboratory trigger test</div>
      </div>
    </div>
    <div class="ESDProtection">
      <div class="box">
        <div class="title">
          <img src="../../../assets/9520网站配图/图标/分组 3.png" alt="" />
          Supreme ESD Protection
        </div>
        <div class="text">
          It can operate normally in an environment with contact discharge of 8
          KV and air discharge of 15 KV, suitable for diverse scanning needs in
          harsh working environments.
        </div>
      </div>
    </div>
    <div class="OS">
      <div class="title">1.2GHz Dual-Core SoC, powered by Linux OS.</div>
      <div class="text">
        Equipped with advanced decoding software and hardware, it rapidly and
        accurately identifies damaged, stained, wrinkled, and colored barcodes,
        suitable for metal and DPM code recognition in light manufacturing.
      </div>
    </div>
    <div class="Agility">
      <div>
        <div class="titleBox">
          <div class="title">Agility 9520 Series</div>
          <!-- <div class="title">Agility 9520</div> -->
        </div>
        <div class="itemBox">
          <div class="one">
            <div class="itemA">
              <div class="title">CPU</div>
              <div class="text">1.2G Dual core</div>
            </div>
            <div class="itemA">
              <div class="title">Light Source</div>
              <div class="text">LED White light</div>
            </div>
            <div class="itemA">
              <div class="title">Aiming Light</div>
              <div class="text">Green Dot</div>
            </div>
            <div class="itemA">
              <div class="title">Image Sensor</div>
              <div class="text">1280*800pixels (Global Shutter)</div>
            </div>
            <div class="itemA">
              <div class="title">Imaging Speed</div>
              <div class="text">120FPS/S</div>
            </div>
            <div class="itemA">
              <div class="title">Min. Print Contrast</div>
              <div class="text">20% Reflection Difference</div>
            </div>
            <div class="itemA">
              <div class="title">Motion Tolerance</div>
              <div class="text">2M/S</div>
            </div>
            <div class="itemA">
              <div class="title">Image Field of View</div>
              <div class="text">Horizontal: 50° Vertical: 38°</div>
            </div>
            <div class="itemA">
              <div class="title">Reading Indicators</div>
              <div class="text">LED light&Buzzer</div>
              <div class="text">(adjustable tone and volume)</div>
            </div>
            <div class="itemA">
              <div class="title">Input Voltage</div>
              <div class="text">5 VDC (+/- 10%)/500mA</div>
            </div>
            <div class="itemB">
              <div class="title">Current</div>
              <div class="text">Working current (Standard):</div>
              <div class="text">&lt; 280 mA</div>
              <div class="text">Standby current (Standard):</div>
              <div class="text">&lt; 130 mA</div>
            </div>
            <div class="itemB">
              <div class="title">Reading Angle</div>
              <div class="text">Pitch: 0-360° ;</div>
              <div class="text">Roll(Tilt): ± 60° ;</div>
              <div class="text">Skew (Yaw): ± 60°</div>
            </div>
            <div class="itemC">
              <div class="title">Decoding Capability</div>
              <div class="text">
                1D Barcode/Linear Code: Auto-distinguishes & decodes all
                standard 1Ds, incl. GS1 DataBar™.
              </div>
              <div class="text">
                2D Barcode: Aztec, Data Matrix, Maxi, QR, HAN XIN, Micro QR.
              </div>
              <div class="text">
                Stacked Barcode: GS1 DataBar Expanded, Stacked, Omnidirectional,
                Composite, MicroPDF417, PDF417.
              </div>
            </div>
            <div class="itemD">
              <div class="title">Typical depth of Field</div>
              <div class="table">
                <div class="item">
                  <div class="text">Precision</div>
                  <div class="text">3.34mil</div>
                  <div class="text">5mil</div>
                  <div class="text">3.34mil</div>
                  <div class="text">5mil</div>
                  <div class="text">13.34mil</div>
                  <div class="text">5mil</div>
                  <div class="text">10mil</div>
                  <div class="text">10mil</div>
                  <div class="text">20mil</div>
                </div>
                <div class="item">
                  <div class="text">Barcode</div>
                  <div class="text">CODE 128</div>
                  <div class="text">CODE128</div>
                  <div class="text">EAN-13</div>
                  <div class="text">EAN-13</div>
                  <div class="text">EAN-13</div>
                  <div class="text">PDF417</div>
                  <div class="text">QR</div>
                  <div class="text">DM</div>
                  <div class="text">QR</div>
                </div>
                <div class="item">
                  <div class="text">Depth of Field</div>
                  <div class="text">120mm—160mm</div>
                  <div class="text">100mm—210mm</div>
                  <div class="text">120mm—160mm</div>
                  <div class="text">100mm—220mm</div>
                  <div class="text">60mm—470mm</div>
                  <div class="text">100mm—210mm</div>
                  <div class="text">90mm—280mm</div>
                  <div class="text">90mm—240mm</div>
                  <div class="text">60mm—440mm</div>
                </div>
              </div>
            </div>
            <div class="itemE">
              <div class="title">Environmental Parameter</div>
              <div class="text">
                ESD Static Protection: Contact discharge 8 kV; Air discharge 15
                kV
              </div>
              <div class="text">
                Drop Resistance: Withstands multiple drops from 1.5 meters onto
                a cement surface
              </div>
              <div class="text">
                Temperature: Operating -10 to 50 °C; Storage/Transport: -40 to
                70 °C
              </div>
              <div class="text">Humidity (non-condensing): 5 - 95%</div>
              <div class="text">Ambient Light: 0 to 100,000 lux</div>
            </div>
            <div class="itemF">
              <div class="title">Dimensions</div>
              <div class="rightImg"></div>
            </div>
          </div>
        </div>
        <!-- <div class="downloads">
          <div class="box">
            <div class="itemA">
              <div class="title">9520</div>
              <div class="text">PDF(1.08MB)</div>
              <div class="icon"><i class="el-icon-bottom"></i></div>
            </div>
            <div class="itemB">
              <div class="title">9520 User Manual</div>
              <div class="text">PDF(1.08MB)</div>
              <div class="icon"><i class="el-icon-bottom"></i></div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import ImgVideo from '@/components/imgVideo'
import video from '../../../assets/ACE 1 页面配图/Compax_Ace_1_Video.mp4'
export default {
  components: {
    ImgVideo
  },
  data() {
    return {
      video
    }
  }
}
</script>

<style lang="less" scoped>
.series {
  .banner {
    position: relative;
    height: 400px;
    background: url('../../../assets/9520网站配图/首页M.png') no-repeat center;
    background-size: cover;
    .textBox {
      margin-top: 15px;
      flex-direction: column;
      width: 100%;
      display: flex;
      justify-content: center;
      .title {
        text-align: center;
        color: rgba(0, 92, 255, 1);
        font-size: 26px;
        font-weight: 700;
        line-height: 37.65px;
      }
      .smallTitle {
        text-align: center;
        margin: 0px 0px 11px 0px;
        color: #fff;
        font-size: 12px;
        line-height: 17.38px;
      }
      .iconTextBox {
        margin: 0px auto;
        img {
          margin-right: 5px;
        }
        .left {
          display: flex;
          align-items: center;
          img {
            width: 24.77px;
            height: 17.93px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          img {
            width: 19.17px;
            height: 18px;
          }
        }
        display: flex;
        justify-content: space-between;
        width: 190px;
        display: flex;
        color: #fff;
        font-size: 10px;
        line-height: 14.48px;
      }
    }
  }
  .video {
    height: 219.42px;
    video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: fill;
      object-position: 50% 60%;
    }
  }
  .high-definition {
    height: 219.38px;
    position: relative;
    background: url('../../../assets/9520网站配图/灯介绍.png.png') no-repeat
      center;
    background-size: cover;
    background-color: #000;
    .box {
      color: #fff;
      position: absolute;
      top: 68px;
      left: 196px;
      .title {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 600;
        line-height: 40px;
        img {
          margin-right: 7px;
          width: 23.59px;
          height: 24.53px;
        }
      }
      .text {
        margin-left: 30.59px;
        width: 130px;
        font-size: 8px;
        line-height: 11.58px;
        text-align: justify;
      }
    }
  }
  .preciseRecognition {
    height: 219.38px;
    position: relative;
    background: url('../../../assets/9520网站配图/扫码场景.png.png') no-repeat
      center;
    background-size: cover;
    .box {
      position: absolute;
      top: 65.5px;
      left: 19px;
      color: #fff;
      .title {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 600;
        line-height: 40px;
        img {
          margin-right: 7px;
          width: 26px;
          height: 26px;
        }
      }
      .text {
        width: 145px;
        font-size: 8px;
        line-height: 11.58px;
        text-align: justify;
      }
    }
  }
  .product {
    position: relative;
    height: 219.38px;
    background: url('../../../assets/9520网站配图/外形2.png') no-repeat center;
    background-size: cover;
    .line {
      top: 63.14px;
      left: 96px;
      position: absolute;
      height: 121px;
      width: 181.84px;
      background: url('../../../assets/9520网站配图/图标/9520链接线M.png')
        no-repeat center;
      background-size: cover;
    }
    .text {
      color: #fff;
      font-size: 8px;
      font-weight: 600;
    }
    .scanWindow {
      position: absolute;
      top: 48px;
      left: 56px;
    }
    .Button {
      position: absolute;
      top: 121.65px;
      left: 90px;
    }
    .Buzzer {
      position: absolute;
      top: 48.5px;
      left: 272px;
    }
    .DataCableInterface {
      position: absolute;
      top: 157.63px;
      left: 55px;
    }
    .Indicator {
      position: absolute;
      top: 117.63px;
      left: 272px;
    }
  }
  .Wide-angle {
    height: 219.38px;
    position: relative;
    background: url('../../../assets/9520网站配图/扫描角度.png') no-repeat
      center;
    background-color: #000;
    background-size: cover;
    .box {
      color: #fff;
      position: absolute;
      top: 65.63px;
      left: 245px;
      .title {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 600;
        img {
          margin-right: 4px;
          width: 19px;
          height: 17px;
        }
      }
      .text {
        width: 123px;
        font-size: 8px;
        line-height: 11.58px;
        text-align: justify;
        margin-top: 5px;
      }
    }
  }
  .Global {
    height: 219.38px;
    position: relative;
    background: url('../../../assets/9520网站配图/全局光照.png') no-repeat
      center;
    background-color: #000;
    background-size: cover;
    .box {
      position: absolute;
      top: 36px;
      left: 35px;
      color: #fff;
      .title {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 600;
        img {
          margin-right: 4px;
          width: 24px;
          height: 24px;
        }
      }
      .text {
        width: 169px;
        font-size: 8px;
        line-height: 11.58px;
        text-align: justify;
        margin-top: 6px;
      }
    }
  }
  .Trigger {
    height: 219.38px;
    position: relative;
    background: url('../../../assets/9520网站配图/按键3.png.png') no-repeat
      center;
    background-color: #000;
    background-size: cover;
    .box {
      position: absolute;
      top: 76.63px;
      left: 204px;
      color: #fff;
      .title {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 600;
        img {
          margin-right: 4px;
          width: 24px;
          height: 24px;
        }
      }
      .text {
        width: 157px;
        font-size: 8px;
        line-height: 11.58px;
        text-align: justify;
        margin-top: 5px;
      }
      .smallText {
        font-size: 4px;
        margin-top: 18px;
      }
    }
  }
  .IP52Protection {
    height: 219.38px;
    position: relative;
    background: url('../../../assets/9520网站配图/防摔.png') no-repeat center;
    background-size: cover;
    .box {
      position: absolute;
      top: 25.63px;
      left: 32px;
      color: #fff;
      .title {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 600;
        img {
          margin-right: 4px;
          width: 21px;
          height: 24px;
        }
      }
      .text {
        width: 157px;
        font-size: 8px;
        line-height: 11.58px;
        text-align: justify;
        margin-top: 5px;
      }
      .smallText {
        font-size: 4px;
        margin-top: 18px;
      }
    }
  }
  .ESDProtection {
    height: 219.38px;
    position: relative;
    background: url('../../../assets/9520网站配图/防静电.png.png') no-repeat
      center;
    background-size: cover;
    .box {
      position: absolute;
      top: 80px;
      left: 199px;
      color: #fff;
      .title {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 600;
        img {
          margin-right: 4px;
          width: 13px;
          height: 15px;
        }
      }
      .text {
        width: 160px;
        font-size: 8px;
        line-height: 11.58px;
        text-align: justify;
        margin-top: 5px;
      }
    }
  }
  .OS {
    height: 219.38px;
    background: url('../../../assets/9520网站配图/cpu2.png.png') no-repeat
      center;
    background-color: #000;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    .title {
      margin-top: 11px;
      line-height: 13.26px;
      font-weight: 600;
      font-size: 10px;
    }
    .text {
      margin-top: 3px;
      width: 319px;
      font-size: 8px;
      line-height: 11.58px;
      text-align: justify;
    }
  }
  .Agility {
    background: rgba(0, 0, 0, 1);
    padding: 27.46px 15px 0px;
    .titleBox {
      position: relative;
      font-weight: 500;
      line-height: 28.96px;
      color: rgba(0, 92, 255, 1);
      font-size: 20px;
      padding-bottom: 6px;
      border-bottom: 2px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -2px;
        content: '';
        width: 175px;
        height: 0px;
        border-bottom: 2px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
    }
    .itemBox {
      padding: 0px 6px;
      .title {
        font-size: 12px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
      }
      .text {
        font-size: 10px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 23px;
        padding: 7px 0px 0px 10px;
        width: 168px;
        height: 80px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 23px;
        padding: 7px 0px 0px 10px;
        width: 168px;
        height: 140px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(27, 32, 48, 1);
      }
      .itemC {
        margin-top: 23px;
        padding: 7px 0px 0px 10px;
        width: 347px;
        height: 160px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(27, 32, 48, 1);
      }
      .itemD {
        margin-top: 23px;
        padding: 7px 0px 0px 10px;
        width: 347px;
        height: 270px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(27, 32, 48, 1);
        .table {
          display: flex;
          .item {
            margin-right: 22px;
          }
        }
      }
      .itemE {
        margin-top: 23px;
        padding: 7px 0px 0px 10px;
        width: 347px;
        height: 190px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(27, 32, 48, 1);
      }
      .itemF {
        position: relative;
        margin-top: 23px;
        padding: 7px 0px 0px 10px;
        width: 347px;
        height: 270px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(0, 92, 255, 0.5);
        .rightImg {
          color: rgba(255, 255, 255, 1);
          font-size: 12px;
          font-weight: 500;
          position: absolute;
          top: 32px;
          left: 73px;
          width: 254px;
          height: 238px;
          background: url('../../../assets/9520网站配图/尺寸图M.png') no-repeat
            center;
          background-size: cover;
        }
      }
      .one {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    .downloads {
      margin-top: 150px;
      .box {
        padding: 0px 10px;
        display: flex;
        .title {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 5px;
        }
        .text {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: rgba(128, 128, 128, 1);
        }
        .itemA {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 220px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
        .itemB {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 251px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
      }
    }
  }
}
</style>
